/* ==========================================================================
   Preloader
   ========================================================================== */

#preloader {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1000;
	background: #131B4D;
	@include flexbox($flex-flow: column, $justify-content: center, $align-items: center);
	.animation {
		max-width: 250px;
		@include breakpoint(tabletdesktop) {
			max-width: 500px;
		}
	}
}