/* ==========================================================================
   Video player
   ========================================================================== */

.video-border {
	max-width: 300px;
	padding: 5px;
	position: relative;
	@include gradient-border();
	margin: 0 auto;
	@include breakpoint(tabletdesktop) {
		max-width: 1140px;
		padding: 15px;
	}
	.video-wrap {
		position: relative;;
		padding-bottom: 56.25%; /* 16:9 */
		height: 0;
		overflow: hidden;		
		&:before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: 99;
			background: no-repeat center/cover url(../img/video-poster.jpg);
			//pointer-events: none;
		}
		&:after {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: 100;
			background: rgba(24, 39, 90, 0.5);
			//pointer-events: none;
		}
		&.loading {
			button {
				background: no-repeat center/contain url(../svg/loading.svg);
				svg {
					display: none;
				}
			}
		}
		&.playing {
			&:before,
			&:after,
			button {
				display: none;
			}
		}
		iframe,video {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: 98;
		}
		button,a {
			position: absolute;
			z-index: 102;
			top: 50%;
			left: 50%;
			transform: translate( -50%, -50%);
			width: 34px;
			height: 34px;
			border-radius: 50%;
			background-image: linear-gradient(to bottom, rgba(#131c4e,0.1) 0%, rgba(19, 28, 78, 0.28) 100%);
			@include gradient-border();
			//pointer-events: none;
			@include breakpoint(tabletdesktop) {
				width: 130px;
				height: 130px;
			}
			svg {
				width: 12px;
				height: 12px;
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate( -50%, -50%);
				@include breakpoint(tabletdesktop) {
					width: 40px;
					height: 40px;
				}
			}
		}
	}
	&.small {
		@include breakpoint(tabletdesktop) {
			padding: 5px;
		}		
		.video-wrap {		
			button,a {
				@include breakpoint(tabletdesktop) {
					width: 44px;
					height: 44px;
				}
				svg {
					width: 12px;
					height: 12px;
				}
			}
		}
	}
}
		
