
/* ==========================================================================
   News articles. Appear in sidebar and in news section
   ========================================================================== */

article.news-article {
	img {
		width: 100%;
		height: auto;
	}
	p {
		font-size: pem(11);
		letter-spacing: 0.88px;
		margin: 17px 0 10px;
		@include breakpoint(tabletdesktop) {
			font-size: pem(14);
			margin: 27px 0 14px;
		}
	}
}
