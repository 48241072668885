/* ==========================================================================
   Blue horizontal lines
   ========================================================================== */

.blue-lines {
    position: relative;
    &.text {
		text-align: center;
		padding: 8px 0;
		margin: 40px 0 26px;
		p {
			color: #6CC8FB;
		}
    }
    &:before,
    &:after {
	  	content: '';
	  	top: 0;
	  	left: 0;
	  	width: 100%;
	  	height: 1px;
	  	position: absolute;
	  	background-image: linear-gradient(90deg, rgba(#6cc8fb, 0.25), rgba(255, 255, 255, 0.15));
    }
    &:after {
	  	top: auto;
	  	bottom: 0;
    }
}

p.highlight,
div.highlight {
	padding: 17px 15px;
	font-weight: 700;
	margin: 18px 0 0;
	border-style: solid;
	border-width: 1px;
	border-image-source: conic-gradient(from 0.25turn, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.1) 0.12turn, #ebeaa7 0.37turn, rgba(255, 255, 255, 0));
	border-image-slice: 1;
    line-height: 1.43;
    color: #ebeaa7 !important;
    text-transform: uppercase;
	@include breakpoint(tabletdesktop) {
		margin: 0;
		padding: 26px;
		border-width: 2px;
	}
    strong {
    	font-size: pem(18);
		@include breakpoint(tabletdesktop) {
			font-size: pem(24);
		}
    }
}

div.highlight p {
    color: #ebeaa7 !important;
    &:first-child {
    	margin-top: 0;
    }
    &:last-child {
    	margin-bottom: 0;
    }
}