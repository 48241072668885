/* ==========================================================================
   Sidebar
   ========================================================================== */

aside.sidebar {
	position: absolute;	
	top: 0;
	right: 0;
	bottom: 0;	
	background-color: #131b4d;
	z-index: 200;
	transform: translateX(110%);
	transition: transform $transition-speed;
	padding: 44px 15px 23px 0;
	width: 100%;
	@include breakpoint(tabletdesktop) {
		width: 524px;
		padding: 90px 40px 90px 0;
	}
	.scroll-outer {
		max-height: 100%;
		@include custom-scrollbar;
		.scroll-inner {
			padding: 0 19px 0 31px;
			@include breakpoint(tabletdesktop) { 
				padding: 0 47px 0 57px;
			}
		}
	}
	+ .expose {
		transition: background-color $transition-speed;
	}
	&.active {
		transform: translateX(0) !important;
		+ .expose {
			position: fixed;
			z-index: 199;
			top: 0;
			left: 0;
			width: 100%;
			background-color: rgba(21,33,55,.8);
			height: 100%;
		}
	}
	button.close {
		position: absolute;
		top: 11px;
		right: 11px;
		@include breakpoint(tabletdesktop) { 
			top: 30px;
			right: 30px;
		}
	}
	h3,h4 {
		font-size: pem(18);
		font-weight: 700;
  		letter-spacing: 0.18px;
		margin: 0 0 20px;
		text-transform: uppercase;
		@include breakpoint(tabletdesktop) { 
			font-size: pem(32);
			letter-spacing: 1.6px;
			margin: 0 0 43px;
		}
	}
	h4 {
		margin: 20px 0;
		@include breakpoint(tabletdesktop) { 
			margin: 66px 0 20px;
		}
	}
	p {
	    font-size: pem(11);
		letter-spacing: 0.88px;
		margin: 18px 0;
		@include breakpoint(tabletdesktop) { 
			font-size: pem(14);
			margin: 20px 0;
		}
		/* for .blue-lines see componetns/_blue-lines.scss*/
		&.blue-lines {
		    color: #6cc8fb;
		    margin-bottom: 0;
		    position: absolute;
		    left: 0;
		    bottom: 0;
		    width: 100%;
		    height: 70px;
		    @include flexbox($flex-flow: column, $justify-content: center);
			@include breakpoint(tabletdesktop) { 
		    	height: 140px;
			}
		}
	}
	ul.icon-list {
		@include reset-list();
		@include flexbox($flex-flow: row wrap, $justify-content: space-between);
		row-gap: 24px;
		column-gap: 30px;
		margin: 18px 0;
		@include breakpoint(tabletdesktop) { 
			row-gap: 43px;
			column-gap: 64px;
			margin: 36px 0;
		}
		li {
			width: calc(50% - 15px);
			text-align: center;
			padding-bottom: 85px;
			position: relative;
			@include breakpoint(tabletdesktop) {
				width: calc(50% - 32px);
				padding-bottom: 140px;
			}
			&.no-stat {
				padding-bottom: 0;
			}
			span.icon {
				display: inline-block;
				width: 82px;
				height: 82px;
				border-radius: 41px;
				@include gradient-border();
				position: relative;
				@include breakpoint(tabletdesktop) { 
					width: 104px;
					height: 104px;
					border-radius: 52px;
				}
			}
			img {
				width: 82px;
				height: auto;
				@include breakpoint(tabletdesktop) { 
					width: 104px;
				}
			}
			p {
				@include breakpoint(mobile) { 
					margin: 12px 0;
				}
			}
		}
	}
}
