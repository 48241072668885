/* ==========================================================================
   Hot spots
   ========================================================================== */

div.hotspots {
	position: absolute;
	//z-index: 29;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: none;
	//background-repeat: no-repeat;
	//background-position: center;
	//background-size: cover;
	//@include breakpoint(tabletdesktop) {
	//	background-image: none !important;
	//}
	.hotspot {
		background-clip: content-box,border-box;
		background-image: linear-gradient(180deg,#131b4d,#131b4d),conic-gradient(from .28turn,#6cc8fb,#fff .09turn,#6cc8fb .33turn,#131c4e .62turn,#6cc8fb);
		background-origin: border-box;
		border-image-slice: 1;
		border-image-source: conic-gradient(from .28turn,#6cc8fb,#fff .09turn,#6cc8fb .33turn,#131c4e .62turn,#6cc8fb);
		border-radius: 25px;
		border-style: solid;
		border-width: 1px;
		flex-grow: 0;
		height: 50px;
		width: 50px;
		position: absolute;
		z-index: 40;
		transform: translate(-50%,-50%);
		cursor: pointer;
		//transition: all $transition-speed cubic-bezier(0.68, -0.55, 0.265, 1.55);
	    &:before {
		  	content: "";
	        width: 12px;
			height: 12px;
			background-color: #c3e7f6;
			border-radius: 6px;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%,-50%);
		}
		@include gradient-border;
		&:after {
			width: 0;
			height: 0;
			top: 50%;
			left: 50%;
			transform: translate(-50%,-50%);
			transition: all $transition-speed cubic-bezier(0.68, -0.55, 0.265, 1.55);
			opacity: 0;
			border-radius: 34px;
		}
		&.active {
			@include breakpoint(tabletdesktop) {
				z-index: 40;
			}
			&:after {
		        width: 68px;
		        height: 68px;
				opacity: 1;
			}
		}
	}
	/*temp*/
	button.back {
		position: absolute;
		z-index: 30;
		right: 50%;
		bottom: 99px;
		transform: translateX(50%);
		font-size: pem(10);
		font-weight: 700;
		letter-spacing: 0.88px;
		color: #C3E7F6;
		text-transform: uppercase;
		@include plain-button;
		height: 49px;
		padding: 13px 18px;
		border-radius: 24.5px;
		@include gradient-border();
		background: #131B4D;
	    line-height: 1;
		@include breakpoint(tabletdesktop) {
			right: 4%;
			top: 50%;
			transform: translateY(-50%);
		}
		&:before {
    		content: '\d7';
		    font-size: 25px;
		    margin-right: 8px;
		    font-family: "Times New Roman";
		    display: inline-block;
		    vertical-align: middle;
		}
	}
}

/*individual hotspot positioning*/
#section1 {
	.hotspot1,
	.popup1 {
		left: 84%;
    	top: 39%;
	}
	.hotspot2,
	.popup2 {
		left: 65%;
		top: 62%;
	}
	.hotspot3,
	.popup3 {
		left: 91%;
    	top: 31%;
	}
	.hotspot4,
	.popup4 {
	    left: 30%;
    	top: 50%;
	}
}
#section2 {
	.hotspot1,
	.popup1 {
		left: 77%;
    	top: 39%;
	}
	.hotspot2,
	.popup2 {
		left: 51%;
    	top: 34%;
	}
	.hotspot3,
	.popup3 {
		left: 67%;
    	top: 57%;
	}
	.hotspot4,
	.popup4 {
		left: 52%;
    	top: 54%;
	}
	.hotspot5,
	.popup5 {
		left: 57%;
    	top: 18%;
	}
	.hotspot6,
	.popup6 {
	    left: 75%;
    	top: 20%;
	}
}
#section3 {
	div.hotspots {
		@include breakpoint(mobile) {
			&:after {
				content: '';
				position: absolute;
				bottom: 0;
				left: 0;
				width: 100%;
				z-index: 10;
				height: 28%;
				background: linear-gradient(180deg, transparent 0, #6BB0D2 55%);
			}
		}
	}
	.hotspot1,
	.popup1 {
		left: 48%;
    	top: 52%;
	}
	.hotspot2,
	.popup2 {
		left: 22%;
    	top: 56%;
	}
	.hotspot3,
	.popup3 {
		left: 38%;
    	top: 26%;
	}
}