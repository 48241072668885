/* ==========================================================================
   Buttons
   ========================================================================== */

.button {
	appearance: none;
	margin: 0; 
	background-color: transparent;
	padding: 16px 33px; 
	height: 40px;
	
	font-size: rem(8);
    font-weight: 700;
    letter-spacing: 0.88px;
    text-align: center;
    color: #C5E6F8;
    
	display: inline-block;
	text-transform: uppercase;
	position: relative;
	transition: all $transition-speed cubic-bezier(0.16, 1, 0.3, 1);
	border: none;
    box-shadow: -20px 20px 200px 0 rgba(112, 30, 136, 0.5);
    
    /*
    border-style: solid;
    border-width: 1px;
    border-image-source: conic-gradient(from 0.28turn, rgba(255, 255, 255, 0.69), rgba(255, 255, 255, 0.1) 0.09turn, rgba(255, 255, 255, 0) 0.33turn, #6cc8fb 0.62turn, rgba(255, 255, 255, 0.69));
    border-image-slice: 1;
    */
    
	@include gradient-border();
    background: linear-gradient(261.42deg, rgba(255, 255, 255, 0.1) -293.41%, rgba(255, 255, 255, 0) 100%);
  
	@include breakpoint(tabletdesktop) {
		font-size: rem(10);
		padding: 21px 54px; 
		height: 54px;
	}
	&:disabled,
	&[disabled],
	&.disabled {
	    opacity: 0.5;
	}
	&:before {
		content: '';
		width: 0;
		height: 0;
		border-style: solid;
		border-width: 5px 0 5px 10px;
		border-color: transparent transparent transparent #fff;		
		position: absolute;
		top: 50%;
		right: 50px;
		transform: translateY(-50%);
		transition: all $transition-speed cubic-bezier(0.16, 1, 0.3, 1);
		opacity: 0;
	}
	&:hover {
		color: #fff;
		padding: 21px 64px 21px 44px; 
    	background-image: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.3) 100%);
		
		&:before {
			opacity: 1;
			margin-right: -10px;
		}
	}
}

/*remove default styling from a button*/
button.plain {
	@include plain-button;
	&.close {
		width: 24px;
		height: 24px;
		border-radius: 12px;
		@include gradient-border();
		@include breakpoint(tabletdesktop) { 
			width: 30px;
			height: 30px;
			border-radius: 15px;
		}
		&:before {
		    position: absolute;
		    top: 50%;
		    left: 50%;
			transform: translate(-50%, -50%);
    		content: '\d7';
		    font-size: 25px; 
		    color: #c5e6f8;
		    font-family: "Times New Roman";
		}
	}
}

button {
	cursor: pointer;
}

.glow-button {
    //--button-background: #131b4d;
    --button-background: rgba(255, 255, 255, .1);
    --button-color: #C5E6F8;
    --button-shadow: rgba(33, 4, 104, 0.2);
    --button-shine-left: #6cc8fb;
    --button-shine-right: #131c4e;
    --button-glow-start: #6cc8fb;
    --button-glow-end: #fff;
    appearance: none;
    outline: none;
    border: none;
    font-family: inherit;
    border-radius: 0;
    position: relative;
    cursor: pointer;
    color: var(--button-color);
    padding: 0;
    margin: 0;
    background: none;
    z-index: 1;
    box-shadow: 0 8px 20px var(--button-shadow);
    //border: solid 1px #fff;
	
	font-size: rem(8);
    font-weight: 700;
    letter-spacing: 0.88px;
	text-transform: uppercase;
    text-align: center;
    
	@include breakpoint(tabletdesktop) {
		font-size: rem(11);
	}

  	.gradient {
		position: absolute;
		inset: 0;
		border-radius: inherit;
		//overflow: hidden;
		//-webkit-mask-image: -webkit-radial-gradient(white, black);
		//transform: scaleY(1.02) scaleX(1.005) rotate(0deg);		
		
		clip-path: polygon(0% 0%, 0% 100%, 1px 100%, 1px 1px, calc(100% - 1px) 1px, calc(100% - 1px) calc(100% - 1px), 1px calc(100% - 1px), 1px 100%, 100% 100%, 100% 0%);
		
		&:before {
			content: '';
			position: absolute;
			top: 50%;
			left: 50%;
			width: 100%;
			height: 0;
			padding-bottom: 100%;
			transform-origin: center center;
			transform: translate(-50%, -50%) scale(1.1) rotate(0deg) translateZ(0);
			//border-radius: 50%;
			//background: linear-gradient(90deg, var(--button-shine-left), var(--button-shine-right));
			background-image: conic-gradient(from 0deg at 50% 50%,#6cc8fb 0,#fff 28%,hsla(0,0%,100%,.569) 38%,#131b4d00 63%,#131b4d00 73%,#6cc8fb 88%);
			animation: rotate linear 5s infinite;
		}
  	}

	span {
	    z-index: 1;
	    position: relative;
	    display: block;
		padding: 16px 0; 
	    width: 114px;
	    border-radius: inherit;
	    background-color: var(--button-background);
	    overflow: hidden;
	    -webkit-mask-image: -webkit-radial-gradient(white, black);
	    
		@include breakpoint(tabletdesktop) {
			width: 196px;
			padding: 21px 0; 
		    &:before {
				content: '';
				position: absolute;
				left: -32px;
				top: -32px;
				transform: translate(var(--pointer-x, 0px), var(--pointer-y, 0px)) translateZ(0);
				width: 64px;
				height: 64px;
				border-radius: 50%;
				background-color: var(--button-glow, transparent);
				opacity: var(--button-glow-opacity, 0);
				transition: opacity var(--button-glow-duration, .5s);
				filter: blur(40px);
	    	}
		}
    }

    &:hover {
	    --button-glow-opacity: 1;
	    --button-glow-duration: .25s;
    }
}

@keyframes rotate {
  to {
    transform: translate(-50%, -50%) scale(1.1) rotate(360deg) translateZ(0);
  }
}


