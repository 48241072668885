/* ==========================================================================
   Side nav
   ========================================================================== */

nav#navigation {
	display: none;	
	@include breakpoint(tabletdesktop) {
		position: fixed;
		z-index: 100;
		left: 4%;
		top: 50%;
		transform: translateY(-50%);
		box-shadow: -20px 20px 200px 0 rgba(112, 30, 136, 0.5);
		body.language-chooser & {
			display: block;
		}
		ul {
			@include reset-list();
			li {
				a {
					display: block;
					position: relative;
					opacity: 0.5;
				    box-shadow: -20px 20px 200px 0 rgba(112, 30, 136, 0.5);
				    padding: 4px 0;
					height: 11px;
					width: 16px;
					&:before {
						content: '';
						display: block;
						width: 8px;
						height: 3px;  
						background-color: #6cc8fb;
					}
					.label {
						display: none;
						@include breakpoint(desktops) {
							display: block;
							font-size: pem(12);
							letter-spacing: 1.5px;
							color: #6cc8fb;
							position: absolute;
							top: 50%;
							left: 50%;
							padding-left: 40px;
							text-transform: uppercase;
							white-space: nowrap;
							transform: translateY(-50%);
							pointer-events: none;
							opacity: 0;
							transition: all $transition-speed;
						}
					}
					&:hover {
						@include breakpoint(desktops) {
							.label {
								opacity: 1;
								padding-left: 21px;
							}
						}
					}
				}
			}
		}
	}
}

