/* ==========================================================================
   scroll tip with animation
   ========================================================================== */

.scroll-tip {
	position: absolute;
	z-index: 28;
	left: 50%;
	text-align: center;
	transform: translateX(-50%);
	bottom: 12px;
	//color: #fff;				
 	//mix-blend-mode: difference;
 	cursor: pointer;
	@include breakpoint(tabletdesktop) {
		bottom: 50px;
	}
	p {
	    font-weight: 700;
		font-size: pem(8);
		line-height: 28px;
		letter-spacing: 0.875px;
		text-transform: uppercase;
		color: #C5E6F8;
   		transition: color $transition-speed;
   		margin: 0;
		@include breakpoint(tabletdesktop) {
			font-size: pem(10);
		}
		@include breakpoint(largedesktop) {
	    	font-size: pem(14);
		}
	}
	.circle {
		display: inline-block;
		width: 40px;
		height: 40px;
		--size: 40px;
		--stroke-size: 1px;
		--diff: calc(calc(var(--size)/2) - var(--stroke-size));
	    --button-glow-start: #6cc8fb;
	    --button-glow-end: #fff;
		border-radius: 50%;
    	background: rgba(255, 255, 255, .1);
    	position: relative;
    	overflow: hidden;
		@include breakpoint(tabletdesktop) {
			width: 50px;
			height: 50px;
			--size: 50px;
		}
    	&:after {
    		content: '';
    		position: absolute;
    		top: 0;
    		left: 0;
    		width: 100%;
    		height: 100%;
			background-image: conic-gradient(from 0deg at 50% 50%,#6cc8fb 0,#fff 28%,hsla(0,0%,100%,.569) 38%,#131b4d00 63%,#131b4d00 73%,#6cc8fb 88%);
			border-radius: 50%;
			mask:radial-gradient(circle var(--diff),transparent 98%,#fff 100%);
			transform-origin: center center;
			transform: rotate(0deg) translateZ(0);
			animation: rotate2 linear 5s infinite;
    	}
		@include breakpoint(tabletdesktop) {
		    &:before {
				content: '';
				position: absolute;
				left: -32px;
				top: -32px;
				transform: translate(var(--pointer-x, 0px), var(--pointer-y, 0px)) translateZ(0);
				width: 64px;
				height: 64px;
				border-radius: 50%;
				background-color: var(--button-glow, transparent);
				opacity: var(--button-glow-opacity, 0.1);
				transition: opacity var(--button-glow-duration, .5s);
				filter: blur(40px);
	    	}
		}
	}
	svg.icon {
		width: 8px;
		height: 16px;
		position: absolute;
		z-index: 102;
		transform: translateX(-50%);
		left: 50%;
		bottom: 17px;
    	transition: color $transition-speed;
		color: #C5E6F8;
		@include breakpoint(tabletdesktop) {
			height: 22px;
			bottom: 19px;
		}
	}
	body.dark & {
		p,
		svg.icon {
			color: #131B4D;
		}
		.circle:before {
			//background: conic-gradient(from 0.28turn, #6cc8fb, #fff 0.09turn, #6cc8fb 0.33turn, #131c4e 0.62turn, #6cc8fb);
		}
	}
	@include breakpoint(mobile) {
		body.mobile-dark & {
			p,
			svg.icon {
				color: #131B4D;
			}
			.circle {
				//background: conic-gradient(from 0.28turn, #6cc8fb, #fff 0.09turn, #6cc8fb 0.33turn, #131c4e 0.62turn, #6cc8fb);
			}
		}
	}
}

@keyframes rotate2 {
  to {
    transform: rotate(360deg) translateZ(0);
  }
}