/* ==========================================================================
   Mixins
   ========================================================================== */

@use "sass:math";

/* 
 * Convert PX units to EMs.
 * Ex: margin-right: pem(16);
 * This assumes base font size is 16px;
 * Source: https://gist.github.com/mrdanadams/2237465
 */
@function pem($pxval, $base: 16) {
  	@return #{math.div($pxval, $base)}em;
}

/* 
 * Convert PX units to REMs.
 * Ex: margin-right: rem(16);
 * This assumes base font size is 16px;
 * Source: https://gist.github.com/mrdanadams/2237465
 */
@function rem($pxval, $base: 16) {
  	@return #{math.div($pxval, $base)}rem;
}

/* 
 * Remove styling from lists
 */
@mixin reset-list() {
    padding: 0;
    margin: 0;
    list-style-type: none;
}

/* 
 * For flexboxes
 */
@mixin flexbox($flex-flow: row, $justify-content: flex-start, $align-items: stretch) {
	display: flex;
	flex-flow: $flex-flow;
	justify-content: $justify-content;
	align-items: $align-items;
}

/* 
 * For our breakpoints
 * Ref: http://responsivedesign.is/develop/getting-started-with-sass
 */
@mixin breakpoint($point) {

	@if $point == mobile {/*rarely need to use this one since the build is mobile first*/
     	@media (max-width: 767px) { @content ; }
   	}
	@if $point == tablet {
     	@media (min-width: 768px) and (max-width: 1440px) { @content ; }
   	}
	@if $point == tabletdesktop {
     	@media (min-width: 768px) { @content ; }
   	} 	
	@if $point == desktop {
     	@media (min-width: 1440px) and (max-width: 1919px) { @content ; }
   	}
	@if $point == desktops {
     	@media (min-width: 1440px) { @content ; }
   	} 
	@if $point == largedesktop {
     	@media (min-width: 1920px) { @content ; }
   	}

}

/* 
 * For our gradient border
 */
@mixin gradient-border {
    &:after {
	    content: "";
	    position: absolute;
	    inset: 0;
	    border-radius: inherit;
	    padding: 1px; /* control the border thickness */
	    background: conic-gradient(from 0deg at 50% 50%,#6cc8fb 0,#fff 28%,hsla(0,0%,100%,.569) 38%,rgba(19,27,77,0) 63%,rgba(19,27,77,0) 73%,#6cc8fb 88%);
	    mask: 
		    linear-gradient(#fff 0 0) content-box, 
		    linear-gradient(#fff 0 0);
	   -webkit-mask-composite: xor;
	           mask-composite: exclude;
	   pointer-events: none;
	   //animation1 is defined in _typography.scss
	   animation: animation1 infinite 10s linear;
	}
}

//for plain buttons
@mixin plain-button {
	border-radius: 0;
	border: none;
	background: transparent;
	cursor: pointer;
	padding: 0;
}

/* 
 * For our custom scrollbars
 */
@mixin custom-scrollbar {
	overflow-y: auto;
	overscroll-behavior: contain;
	outline: none;
	overflow-x: hidden;
	/*
	https://css-tricks.com/almanac/properties/s/scrollbar/
	https://css-tricks.com/the-current-state-of-styling-scrollbars-in-css/
	*/
	/* Foreground, Background */
	scrollbar-color: #6cc8fb rgba(114, 116, 119, 0.6);
	
	&::-webkit-scrollbar {
	    width: 17px; /* Mostly for vertical scrollbars */
	}
	&::-webkit-scrollbar-thumb { /* Foreground */
	    width: 17px;
	    //background: transparent no-repeat top center/17px url(../img/scrollbar-thumb.png);
	    background: transparent no-repeat top center/17px 100% url(../img/scrollbar-thumb.png);
	}
	&::-webkit-scrollbar-track { /* Background */
	    background: linear-gradient(90deg, transparent 8px, rgba(114, 116, 119, 0.6) 9px, transparent 10px);
	}
}