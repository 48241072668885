/* ==========================================================================
   Section
   ========================================================================== */

.section {
	min-height: 568px;
	height: 100vh; /* Use vh as a fallback for browsers that do not support Custom Properties */
	position: relative;
	overflow: hidden;//for video
	
   	//this smooths the repositioning of the centered content when the screen resizes
	@include breakpoint(tabletdesktop) {
	    transition-duration: .5s;
	    transition-delay: 0s;
	    transition-timing-function: cubic-bezier(.215,.61,.355,1); 
	}
    
	@include flexbox($justify-content: center, $align-items: center);
	    
	@include breakpoint(mobile) {
		height: var(--vh, 1vh);//this is set in js. It's a fix for mobile.
	}
			
	.content {
		text-align: center;
		width: 280px;
		position: relative;
		z-index: 11;
		visibility: hidden;
		@include breakpoint(tablet) {
			width: 600px;
		}
		@media (min-width: 1024px) {
			width: 1000px;
		}		
		h1, h2 {
			font-size: pem(30);
  			letter-spacing: 1.5px;
			font-weight: 700;
			text-transform: uppercase;
			margin: 0;
			@include breakpoint(tabletdesktop) {
				font-size: pem(64);
				letter-spacing: 0.05em;
			}
		} 
		h2 {
			color: rgba(19, 27, 77, 0.9);
		}
		p {
		    font-size: pem(12);
		    letter-spacing: 0.88px;
		    margin: 20px auto 0;
			@include breakpoint(tabletdesktop) {
		    	font-size: pem(14);
				margin: 30px auto 0;
		    	max-width: 398px;
			}
			@include breakpoint(largedesktop) {
		    	font-size: pem(16);
			}
		    &.intro {
		    	transform-origin: center;
		    	max-width: 245px;
				@include breakpoint(tabletdesktop) {
			    	max-width: 398px;
				}
		    }
		    &.buttons {
		    	transform-origin: center;
				@include flexbox($justify-content: center, $align-items: center);
				column-gap: 17px;
				@include breakpoint(desktops) {
					column-gap: 24px;
				}	
				@include breakpoint(mobile) {
					.button {
						width: 114px;
						padding-left: 0;
						padding-right: 0;
					}			
				}			
		    }
		}
	}
	&.video {
		background-position: center;
		/*
		@include breakpoint(mobile) {
			&:before {
				content: '';
			    position: absolute;
			    z-index: 2;
			    top: 0;
			    left: 0;
			    width: 100%;
			    height: 100%;				
				background: linear-gradient(180deg, rgba(31,172,223,0) 16%, rgba(31,172,223,1) 30%, rgba(19,27,77,1) 66%);
			}
		}*/
		.content {
			@include breakpoint(mobile) {
				width: 245px;
			}
			h2 {
			    color: #131B4D;
			}
			p,
			p.buttons .button,
			p.buttons .glow-button {
			    color: #131B4D;
			}
   			p.buttons .button:before {
   				border-color: transparent transparent transparent #131B4D;
   			}
		}
		> video {
		    position: absolute;
		    z-index: -1;
		    top: 0;
		    left: 0;
		    width: 100%;
		    height: 100%;
		    object-fit: cover;
		    object-position: center;
		}
		&.hotspots .scroll-tip {
			display: none;
		}
	}		
}
