
body, button, input, select, textarea {
	font-family: Helvetica, 'Helvetica Neue', Arial, sans-serif; 
	color: #fff;
	line-height: 1.2;
	-webkit-font-smoothing: subpixel-antialiased;
	-moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
}

a {
	color: inherit;
	text-decoration: underline;
}

article.news-article,
.sidebar .message,
.popup {
	p.more {
		text-transform: uppercase;
		margin: 0;
		font-size: pem(8);
		font-weight: bold;
		line-height: 3.5;
		@include breakpoint(tabletdesktop) {
			font-size: pem(10);
			line-height: 2.8;
		}
		svg {
			width: 8px;
			height: 6px;
			color: #fff;
			margin-left: 4px;
			@include breakpoint(tabletdesktop) {
				width: 11px;
				height: 7px;
			}
		}
	}
}

/*this is the animated header text that appears in the intro and sections after 'Air'*/
//for animating background conic gradient
@property --a {
  syntax: '<angle>';
  inherits: false;
  initial-value: 10deg;
}

@keyframes animation1 {
  from {
  	--a:0deg;
  }
  to {
  	--a:360deg;
  }
}
#clia-spa h1,
.section2 h2 {
	--a:0deg; //needed for firefox to have a valid output

	background: conic-gradient(from var(--a) at 50% 50%, rgba(255, 255, 255, 0.65) 0deg, rgba(255, 255, 255, 0.1) 35.62deg, rgba(255, 255, 255, 0) 135deg, #6CC8FB 240deg, rgba(255, 255, 255, 0.65) 360deg), linear-gradient(261.42deg, #FFFFFF -293.41%, rgba(255, 255, 255, 0) 100%);

	animation: animation1 infinite 10s linear;
	
	background-clip: text;
	text-fill-color: transparent;
	-webkit-text-fill-color: transparent;
	color: #fff;
	
}/*

@keyframes animation1 {
    from { 
    	background-position: 0 0; 
    }
    to { 
    	background-position: 100% 0; 
    	background-size: 1728px;
    }
}

h1,
.section2 h2 { 
    background-image: url(../img/water.jpg);
    background-position: 0 0;
    background-repeat: repeat-x;
    background-size: 864px;

    animation: animation1 30s ease-in-out infinite alternate;
    
	background-clip: text;
	text-fill-color: transparent;
	-webkit-text-fill-color: transparent;
	
	opacity: 0.8;
}*/