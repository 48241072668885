/* ==========================================================================
   Popup
   ========================================================================== */

aside.popup {
	display: none;
	width: 304px;
	padding: 28px 21px;
	background-color: rgba(19, 27, 77, 0.9);
    position: absolute;
    z-index: 201;
	@include gradient-border();
	//@include breakpoint(mobile) {
		top: 50% !important;
		left: 50% !important;
		transform: translate(-50%, -50%) !important;
	//}
	@include breakpoint(tabletdesktop) {
		width: 500px;
		padding: 36px;
	}
	&.active {
		display: block;
	}
	/*
	when we want to move where the popup appears in relation to the hotspot
	(default is top left)
	
	&.bottom-left {
		transform: translateY(-100%);
	}
	&.top-right {
		transform: translateX(-100%);
	}
	&.bottom-right {
		transform: translate(-100%,-100%);
	}*/
	h3 {
		font-size: pem(18);
		text-transform: uppercase;
		letter-spacing: 0.9px;
		margin: 0;
		@include breakpoint(tabletdesktop) {
			font-size: pem(30);
  			letter-spacing: 1.5px;
		}
	}
	p {
		font-size: pem(11);
		letter-spacing: 0.88px;
		margin: 12px 0;
		@include breakpoint(tabletdesktop) {
			font-size: pem(14);
			margin: 20px 0;
		}
		&.highlight {
			margin-bottom: 0;
		}
	}
	button.close {
		position: absolute;
		top: 9px;
		right: 12px;
		width: 24px;
		height: 24px;
		/*@include breakpoint(tabletdesktop) {
			display: none;
		}*/
		&:before {
		    font-size: 20px; 
		}
	}
	
	button.more {
		text-transform: uppercase;
		margin: 0;
		font-size: rem(8);
		font-weight: bold;
		line-height: 3.5;
		@include breakpoint(tabletdesktop) {
			font-size: rem(10);
			line-height: 2.8;
		}
	}
	div.more {
		display: none;
	}
	&.more {
		div.less {
			display: none;
		}
		div.more {
			display: block;
		}
	}
}
