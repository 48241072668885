/* ==========================================================================
   A link with an icon
   ========================================================================== */

p.icon-link {
	font-size: pem(10) !important;
	font-weight: bold;
	line-height: 2.8 !important;
	letter-spacing: 0.88px !important;
	color: #c5e6f8 !important;
	margin: 18px 0 0;
	text-transform: uppercase;
	a {
		text-decoration: none;
		span.icon {
			display: inline-block;
			vertical-align: middle;
			margin-right: 12px;
			width: 30px;
			height: 30px;
			border-radius: 15px;
			position: relative;
			@include gradient-border();
			svg {
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				width: 12px;
				height: 12px;
				color: transparent;
				&.case-studies {
					height: 14px;
				}
			}
		}
	}
}