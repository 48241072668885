/* ==========================================================================
   Header
   ========================================================================== */

#clia-spa > header {	
	@include flexbox($flex-flow: row, $justify-content: center, $align-items: center);
	width: 100%;
	padding: 0 4%;
	height: 45px;
	position: fixed;
	top: 0;
	right: 0;
	z-index: 200;
    //mix-blend-mode: hard-light;
	@include breakpoint(tabletdesktop) {
		height: 88px;
		width: 50%;
		padding: 0 4% 0 0;
		justify-content: space-between;
	}
    p {
    	margin: 0;
    	font-weight: 400;
		font-size: pem(10);
    	svg {
    		width: 42px;
    		height: 25px;
    		color: #F5F5F5;
    		transition: color $transition-speed;
			@include breakpoint(tabletdesktop) {
				margin-left: -30px;
	    		width: 60px;
	    		height: 35px;
			}
    	}
    	&.language,
		select.language-selector {
			font-weight: 700;
    	}
    	&.language {
    		display: none;
    	}
    }
    #language-trigger {
    	position: absolute;
    	top: 12px;
    	right: 12px;
		width: 24px;
		height: 24px;
		border-radius: 12px;
		@include gradient-border;
		display: none;
		svg {
			width: 13px;
			height: 21px;
			color: #fff;
		}
    }
    body.dark & {
		p,
		p svg,
		p.language select.language-selector,
		p.language select.language-selector option {
			color: #131B4D;
		}
	    #language-trigger svg {
			color: #131B4D;
	    }
    }
    body.language-chooser & {
    	p.language {
			@include breakpoint(tabletdesktop) {
				display: block;
			}   
    	}
    	#language-trigger {
			@include breakpoint(mobile) {
				display: block;
			}
		}
    }
}