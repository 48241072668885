/* ==========================================================================
   Base styles: opinionated defaults
   ========================================================================== */

html {
    font-size: 1em;
}

body {
	background: #131B4D;
  	overscroll-behavior-y: none;	
  	&.sidebar {
		height: 100%;
	    overflow: hidden;
	}
}

html {
    //https://css-tricks.com/practical-css-scroll-snapping/
    scroll-snap-type: y mandatory;
    &.scrolling {
    	scroll-snap-type: none
    }
}

#clia-spa > section {
    //https://css-tricks.com/practical-css-scroll-snapping/
    scroll-snap-align: start;
}


/*
 * Remove text-shadow in selection highlight:
 * https://twitter.com/miketaylr/status/12228805301
 */

::-moz-selection {
    background: #b3d4fc;
    text-shadow: none;
}

::selection {
    background: #b3d4fc;
    text-shadow: none;
}

/*
 * fix for focus
 * ref: https://css-tricks.com/the-focus-visible-trick/
 */
:focus:not(:focus-visible) { outline: none }

/*
 * A better looking default horizontal rule
 */

hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid #ccc;
    margin: 1em 0;
    padding: 0;
}

/*
 * Remove the gap between audio, canvas, iframes,
 * images, videos and the bottom of their containers:
 * https://github.com/h5bp/html5-boilerplate/issues/440
 */

audio,
canvas,
iframe,
img,
svg,
video {
    vertical-align: middle;
}

/*
 * Remove default fieldset styles.
 */

fieldset {
    border: 0;
    margin: 0;
    padding: 0;
}

/*
 * Allow only vertical resizing of textareas.
 */

textarea {
    resize: vertical;
}

/* apply a natural box layout model to all elements */
*, *:before, *:after {
    box-sizing: border-box;
}


