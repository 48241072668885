/* ==========================================================================
   Fancybox
   ========================================================================== */

.fancybox__container {
	justify-content: center;
    .fancybox__backdrop {
    	//mix-blend-mode: multiply;
  		background: rgba(21, 33, 55, 0.8);
    }
    .fancybox__toolbar {
    	background: transparent;
    	.fancybox__counter,
    	.fancybox__button--zoom,
    	.fancybox__button--slideshow,
    	.fancybox__button--fullscreen,
    	.fancybox__button--thumbs {
    		display: none;
    	}
    }
    .fancybox__thumbs {
   		display: none;
   	}
   	.fancybox__slide {
   		&:before,
   		&:after {
   			display: none;
   		}
   	}
	.fancybox__nav {
		.carousel__button {
			width: auto;
			height: auto;
			bottom: 13px;
			top: auto;
			transform: none;
			column-gap: 5px;
			align-items: center;
			text-transform: uppercase;
			font-size: pem(10);
			line-height: 2.8;
			letter-spacing: 0.88px;
			color: #f5f5f5;
			@include breakpoint(tabletdesktop) {
		    	bottom: 24px;
			}
			svg {
				width: 4px;
				height: 7px;
				stroke-width: 1px;
				stroke-linecap: butt;
			}
			&.is-prev {
				left: 25px;
				@include breakpoint(tabletdesktop) {
			    	left: 45px;
				}
			}
			&.is-next {
				right: 25px;
				@include breakpoint(tabletdesktop) {
			    	right: 45px;
				}
				svg {
					transform: scaleX(-1);
				}
			}
		}
	}
   	.carousel__button.is-close {
    	width: 24px;
		height: 24px;
		border-radius: 12px;
		@include gradient-border();
		@include breakpoint(tabletdesktop) { 
	    	width: 30px;
			height: 30px;
			border-radius: 15px;
		}
		&:before {
		    position: absolute;
		    top: 50%;
		    left: 50%;
			transform: translate(-50%, -50%);
    		content: '\d7';
		    font-size: 25px; 
		    color: #c5e6f8;
		    font-family: "Times New Roman";
		}
		svg {
			display: none;
		}
	}
	/*********************************SHIPS********************************************/
	&.ships {
	    .fancybox__carousel {
	    	background-color: #131B4D;
	    	width: calc(100% - 32px);
	    	height: auto;
	    	flex: none;
	    	margin: 0 auto;
	    	overflow: hidden;
	    	@include gradient-border;
			@include breakpoint(tabletdesktop) { 
				width: 786px;
			}
	    	.fancybox__viewport {
	    		.fancybox__slide {
		    		overflow: hidden;
		    		padding: 48px 36px 60px;
					@include breakpoint(tabletdesktop) { 
						padding: 45px 45px 78px;
					}
					.fancybox__content {
						background: transparent;
						padding: 0;
						.carousel__button.is-close {
							right: -23px;
			    			top: -35px;
							@include breakpoint(tabletdesktop) {
						    	top: -29px;
						    	right: -17px; 
							}
						}
					}
		    	}
	    	}
	    }
		.ship-slide {
			@include flexbox($flex-flow: column, $justify-content: space-between, $align-items: flex-start);
			column-gap: 38px;
			text-transform: uppercase;
			@include breakpoint(tabletdesktop) { 
				flex-flow: row;
				height: 302px;
			}
			.image {
				position: relative;
				padding: 6px;
				@include gradient-border;
				@include breakpoint(tabletdesktop) { 
					width: 50%;
				}
				img {
					width: 100%;
					height: auto;
				}
				p {
					text-align: center;
					font-size: pem(8);
					line-height: 28px;
					letter-spacing: 0.875px;
					color: #fff;
					margin: 0;
					@include breakpoint(tabletdesktop) { 
						font-size: pem(10);
						line-height: 2;
						margin: 13px 0 11px;
					}
				}
			}
			.details {
				@include breakpoint(tabletdesktop) { 
					width: 50%;
				}
				h3 {
					font-size: pem(18);
					line-height: 1;
					color: #6CC8FB;
					margin: 0 0 10px;
					@include breakpoint(tabletdesktop) { 
						font-size: pem(32);
						margin: 5px 0 15px;
					}
				}
				p {
					&.cruise-line {
						color: #fff;
						font-size: pem(8);
						line-height: 20px;
						margin: 15px 0 0;
						@include breakpoint(tabletdesktop) { 
							font-size: pem(14);
							line-height: 2;
							margin: 0 0 5px;
						}
					}
					/*see components/_icon-link.scss for the link with icon*/
				}
				.blue-lines {
					@include flexbox($flex-flow: row wrap, $justify-content: space-between, $align-items: flex-start);
					gap: 17px;
					padding: 20px 0;
					p {
						font-size: pem(8);
						line-height: 12px;
						letter-spacing: 0.88px;
						color: #fff;
						margin: 0;
						width: calc(50% - 11px);
						@include breakpoint(tabletdesktop) { 
							font-size: pem(10);
							line-height: 1.5;
						}
						strong {
							display: block;
						}
					}
				}
			}
		}
	}
	/*********************************NEWS********************************************/
	&.news {
	    .fancybox__carousel {
			background-color: #131b4d;
	    	width: calc(100% - 32px);
	    	margin: 0 auto;
	    	flex: none;
	    	height: auto;
			@include gradient-border();
			padding: 0 0 20px 0;
			overflow: hidden;
			max-height: calc(100vh - 136px);
			@include breakpoint(tabletdesktop) { 
				padding-bottom: 60px;
	    		width: 730px;
	    		max-height: none;
			}
	    	.fancybox__slide {
				padding: 0;
				background: transparent;
	    		overflow: hidden;//stops flash of scrollbar appearing when the popup opens
				.fancybox__content {
					background: transparent;
					padding: 44px 15px 23px 0;
					max-height: 100%;
					@include breakpoint(tabletdesktop) { 
						padding: 70px 30px 0 60px;
					}
					.scroll-outer {
						@include custom-scrollbar;
						max-height: 100%;
						@include breakpoint(tabletdesktop) { 
							max-height: 700px;
						}
						.scroll-inner {
							padding: 0 19px 0 31px;
							@include breakpoint(tabletdesktop) { 
								padding: 0 30px 0 0;
							}
						}
					}
					
	   				.carousel__button.is-close {
	   					top: 12px;
	   					right: 12px;
						@include breakpoint(tabletdesktop) { 
							top: 24px;
	   						right: 24px;
						}
					}
					
					img {
						width: 100%;
						height: auto;
					}
					h1,p,li {
						font-size: pem(11);
						letter-spacing: 0.88px;
						color: #fff;
						@include breakpoint(tabletdesktop) { 
							font-size: pem(14);
						}
					}
					
					h1 {
						font-size: pem(16);
						text-align: center;
					}
					
					.blue-lines.text p {
						color: #6CC8FB;
					}	
					hr {
						border-top-color: #6CC8FB;
					}
				}
	    	}
	    }
	}
	
	/*********************************language selector on mobile********************************************/
	.has-inline {
		background-color: #131b4d;
    	width: calc(100% - 32px);
    	margin: 0 auto;
    	align-self: center;
		padding: 0;
		.fancybox__content {
			@include gradient-border;
		    background: transparent;
			color: #fff;
			display: block;
			margin: 0;
			position: static;
    		padding: 27px 21px;
    		width: 100%;
    		text-align: center;
			select.language-selector {
				text-align: left;
				background: transparent;
				border: none;
				text-transform: uppercase;
				//vertical-align: middle;
		    	padding-right: 5px;
		    	width: 200px;
		    	margin: 10px auto 0;
				option {
					color: #000;
				}
			}
			.carousel__button.is-close {
				top: 12px;
 				right: 12px;
			}
		}
	}
}