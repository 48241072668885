/* ==========================================================================
   Carousel
   ========================================================================== */

.carousel {
	img {
		width: 100%;
		height: auto;
	}
	a {
		text-decoration: none;
	}
}

.tns-nav {
	text-align: center;
	margin-top: 5px;
	button {
		display: inline-block;
		width: 10px;
		height: 10px;
		border-radius: 5px;
		background: #475880;
		margin: 0 4px;
		appearance: none;
		border: none;
		padding: 0;
		&.tns-nav-active {
			background: #C3E7F6;
		}
	}
}

.tns-controls {
	@include flexbox($flex-flow: row, $justify-content: flex-end);
	column-gap: 9px;
	margin-top: 5px;
	button {
		@include plain-button;
		.prev {
			transform: scaleX(-1);
		}
		.next {
			
		}
		&:disabled,
		&[disabled],
		&.disabled {
		    opacity: 0.3;
		}
		svg {
			color: #C5E6F8;
		}
	}
}

.sidebar {
	.carousel-container {
		margin: 0 -26px 0 -31px;
		@include breakpoint(tabletdesktop) { 
			margin: 0 -47px 0 -57px;
		}
	}
	.tns-controls {
		justify-content: space-between;
		padding-left: 31px;
		padding-right: 26px;
		@include breakpoint(tabletdesktop) { 
			padding-left: 47px;
			padding-right: 57px;
		}
		button {
			position: relative;
			top: -25px;
		}
	}
}