/* ==========================================================================
   Footer
   ========================================================================== */

#clia-spa > footer {
	margin: 35px 0;
	@include breakpoint(tabletdesktop) {
		margin: 85px 0 40px;
	}
    p {
    	margin: 0;
    	font-weight: 400;
		font-size: pem(10);
		text-align: center;
    	svg {
    		width: 59px;
    		height: 35px;
    	}
    }
}