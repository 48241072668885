/* ==========================================================================
   Index Page
   ========================================================================== */

article#clia-spa {

	p.language {
		font-size: pem(8);
		line-height: 28px;
		letter-spacing: 0.875px;
		text-transform: uppercase;
		@include breakpoint(tabletdesktop) {
			font-size: pem(10);
		}
		select.language-selector {
			text-align: left;
			background: transparent;
			border: none;
			text-transform: uppercase;
			//vertical-align: middle;
	    	padding-right: 5px;
			option {
				color: #000;
			}
		}
	}
	
	#language {
        p.language span.pulldown {
        	position: relative;
        	&:after {
        		content: '';
        		position: absolute;
        		left: 4px;
        		bottom: -7px;
         		width: calc(100% - 26px);
				height: 1px;
				background-image: linear-gradient(90deg, #6CC8FB 0, #fff 100%);
				opacity: 0.5;
			}		
		}
		@include breakpoint(mobile) {
			.glow-button span {
				width: 160px;
			}
		}
	}
	
	#language,
	#intro {
        &:before {
        	content: '';
        	position: absolute;
        	left: -15.97%;
			right: 72.11%;
			top: 53%;
			bottom: -28.65%;
        	z-index: 1;
		    margin: 13px 160.1px 0 0;
		    filter: blur(280px);
		    background-image: linear-gradient(136deg, rgba(197, 230, 248, 0.15) 5%, rgba(108, 200, 251, 0.15) 39%, rgba(12, 169, 255, 0.15) 101%);
        }
        &:after {
        	content: '';
	        position: absolute;
			left: 75.58%;
			right: -20.93%;
			top: -26.79%;
			bottom: 48.44%;
        	z-index: 1;
			background: linear-gradient(135deg, rgba(197, 230, 248, 0.15) 2.88%, rgba(108, 200, 251, 0.15) 36.12%, rgba(12, 169, 255, 0.15) 98.14%);
			filter: blur(140px);
        }
	}
		
	#section1,
	#section2 {
		aside.sidebar .blue-lines.text {
			text-align: left;
		}
	}
	
	#section2 {
		aside.sidebar ul.icon-list li {
			padding-bottom: 0;
		}
	}
	
	#section3 {
		aside.sidebar h3 {
			margin: 22px 0;
		}
		@include breakpoint(tabletdesktop) {
			&:after {
				content: '';
				position: absolute;
				bottom: 0;
				left: 0;
				width: 100%;
				z-index: 10;
				height: 500px;
				background: linear-gradient(180deg, transparent 0, #6BB0D2 55%);
			}
		}
		.sidebar {
			.message {
				margin-top: 0;
				padding: 11px 0;
				a {
					text-decoration: none;
					@include flexbox();
					gap: 12px;
				}
				.img {
					width: 139px;
					background: no-repeat center/cover url(../img/fr-chairman-message.jpg);
				}
				.content {
					flex: 1;
					p {
						color: #fff;
						text-align: left;
						margin: 0 0 18px;
						&:last-child {
							margin-bottom: 0;
						}
						svg {
							width: 8px;
							height: 6px;
							color: #fff;
							margin-left: 4px;
							@include breakpoint(tabletdesktop) {
								width: 11px;
								height: 7px;
							}
						}
					}
				}
			}
		}
	}
	
	.section2 {
		padding-top: 44px;
		margin-bottom: 70px;
		@include breakpoint(tabletdesktop) {
			padding-top: 88px;
			margin-bottom: 140px;
		}
		h2 {
			font-size: pem(18);
			text-align: center;
			letter-spacing: 0.05em;
			margin: 0 0 30px;
			text-transform: uppercase;
			
			@include breakpoint(tabletdesktop) {
				font-size: pem(30);
				margin: 0 0 60px;
			}
		}
		
	}

	#news,
	#ships {
		//background: linear-gradient(180deg, #6BB0D2 0%, rgba(107, 176, 210, 0) 100%);
		.carousel-container {
			padding-left: 17px;
			padding-right: 17px;
			@include breakpoint(tabletdesktop) {
				padding: 0;
				position: relative;
				&:before,
				&:after {
					content: '';
					position: absolute;
					z-index: 10;
					top: 0;
					bottom: 0;
					width: 200px;
				}
				&:before {
					background-image: linear-gradient(270deg, #FFFFFF00 0%, #131b4d 70%);
					left: 0;
				}
				&:after {
					background-image: linear-gradient(90deg, #FFFFFF00 0%, #131b4d 70%);
					right: 0;
				}
			}
			.news-slider {
				a {
					text-decoration: none;
					//see components/_news-article
				}
			}
			.tns-controls {
				margin-top: 20px;
				justify-content: center;
				@include breakpoint(tabletdesktop) {
					margin-top: 57px;
				}
				svg {
					width: 27px;
				}
			}
		}
	}
	
	#video {
		/*see components/_video.scss*/
	}

	#downloads {		
		position: fixed;	
		right: auto;
		left: 0;
		transform: translateX(-110%);
		ul {
			@include reset-list;
			li {
				text-align: center;		
				margin-bottom: 40px;
				a {
				 	font-size: pem(16);
					font-weight: bold;
					letter-spacing: 0.88px;
					color: #fff;
					text-decoration: none;
					text-transform: uppercase;
				 	img {
						 box-shadow: 0px 0px 15px #fff;
						 width: 200px;
						 height: auto;
					}
					span {
						display: block;
						margin-top: 10px;
					}
				}
			}
		}
	}

	#register {
		position: relative;
		padding: 37px 56px;
		margin: 0 17px;
		@include gradient-border();
		@include breakpoint(tabletdesktop) {
			padding: 70px 95px;
			max-width: 574px;
			margin-left: auto;
			margin-right: auto;
		}
		h2 {
			margin-bottom: 20px;
			@include breakpoint(tabletdesktop) {
				margin-bottom: 45px;
			}
		}
		form {
			position: relative;
			p {
				text-align: center;
				margin: 20px 0;
				@include breakpoint(tabletdesktop) {
					margin: 25px 0;
				}
				&.buttons {
					margin: 20px 0 0;
					@include breakpoint(tabletdesktop) {
						margin: 45px 0 0;
					}
					button {
						width: 144px;
						box-shadow: none;
						@include breakpoint(tabletdesktop) {
							width: 244px;
						}
					}
				}
				label {
					font-size: pem(8);
					line-height: 3.5;
					letter-spacing: 0.88px;
					text-align: right;
					color: #f5f5f5;
					text-transform: uppercase;
					margin-right: 11px;
					width: 74px;
					display: inline-block;
					@include breakpoint(tabletdesktop) {
						font-size: pem(10);
						line-height: 2.8;
						margin-right: 20px;
					}
				}
				.field-wrap {
					position: relative;
					&:after {
						content: '';
						position: absolute;
						bottom: 0;
						left: 0;
						width: 100%;
						height: 1px;
	  					background-image: linear-gradient(90deg, #62769B, #38406A);					
  					}
					input {
						appearance: none;
						border: none;
						background: transparent;
						width: 105px;
						padding: 0;
						@include breakpoint(tabletdesktop) {
							width: 177px;
						}
					}
					select {
						appearance: none;
						border: none;
						background: transparent;
						width: 105px;
						padding: 0;
						@include breakpoint(tabletdesktop) {
							width: 177px;
						}
						option {
							color: #131b4d;
						}
					}
				}
			}
			.h-captcha {
				@include breakpoint(mobile) {
					margin-left: -37px;
				}
				text-align: center;
			}
			.response {
				background: #131C4E;
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;	
				display: none;			
			}
		}
	}
}
